import React from "react"
import MainLayout from "../layouts/MainLayout"
import Seo from "../components/Seo"
import ReactMarkdown from "../components/ReactMarkdown"

import { graphql } from "gatsby"
import "../i18n"
import useChangeLanguage from "../hooks/useChangeLanguage"

export default function Form({ data, pageContext }) {
  const { locale } = pageContext
  useChangeLanguage({ locale })

  return (
    <MainLayout alternativeSlug={data.strapiForm.localizations.data?.[0]?.attributes?.slug} locale={locale}>
      <Seo
        title={`${data.strapiForm.seo.title}`}
        description={`${data.strapiForm.seo.description}`}
      />
      <div className="form">
        <div className="container page-content">
          <ReactMarkdown
            children={data.strapiForm.content.data.content}
            className="ck-content"
          />
        </div>
        <div className="iframe">
          <ReactMarkdown children={data.strapiForm.code} />
        </div>
      </div>
    </MainLayout>
  )
}

export const formQuery = graphql`
  query ($slug: String!) {
    strapiForm(slug: { eq: $slug }) {
      slug
      header
      code
      content {
        data {
          content
        }
      }
      localizations {
        data {
          attributes {
            slug
          }
        }
      }
      seo {
        title
        description
        keywords
      }
    }
  }
`
